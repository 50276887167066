import api from "@/api/portal"
import logError from "@/utils/log_error"

export default {
  state: {
    portal: "Ernst-Abbe-Hochschule Jena",
    colors: {
      fontColor: "black",
      linkColor: "inherit",
      bgMain: "#c50e1f",
      bgAlt: "#f7eeeb",
      bgHigh: "#f99",
      textMain: "#fff",
      textAlt: "#fff",
      textHigh: "#fff",
    },
    contact: {
      email: "info@stellenticket.de",
      name: "Stellenticket GmbH",
      opening_times_hint: true,
      tel: "+49 30 57707448-0",
      web: "www.stellenticket.de",
      logo_url: "",
    },
    header: {
      text: "Stellenticket Portal",
      textShort: "",
      tileUrl: "",
      tileColor: "#000",
    },
    logos: [],
    employers: false,
    matomo: {
      url: null,
      id: null,
    },
    partner: {
      name: "",
      nameShort: "",
    },
    desktopUrl: "",
    welcomeTitle: "",
    welcomeText: "",
    imprint: "",

    fetched: false,
  },
  mutations: {
    setPortal(state, portal) {
      state.portal = portal
    },
    setPortalConfigFetched(state, value) {
      state.fetched = value
    },
    updatePortalConfig(state, config) {
      if (config.colors) {
        state.colors.bgMain = config.colors.main_background
        state.colors.bgAlt = config.colors.alternative_background
        state.colors.bgHigh = config.colors.highlight_background

        state.colors.textMain = config.colors.font_on_main_background
        state.colors.textAlt = config.colors.font_on_alternative_background
        state.colors.textHigh = config.colors.font_on_highlight_background

        state.colors.fontColor = config.colors.font_color
        state.colors.linkColor = config.colors.link_color
      }

      Object.assign(state.contact, config.contact)

      state.header.text = config.header
      state.header.textShort = config.header_short
      state.header.tileUrl = config.header_tile_url
      state.header.tileColor = config.header_tile_color
      state.logos = config.logos

      state.employers = config.employers

      state.matomo.url = config.matomo.base_url
      state.matomo.id = config.matomo.id

      state.partner.name = config.partner
      state.partner.nameShort = config.partner_short

      state.desktopUrl = config.desktop_url
      state.welcomeTitle = config.welcome_title
      state.welcomeText = config.welcome_text
      state.imprint = config.imprint
    },
  },
  actions: {
    changePortal({ commit, dispatch }, value) {
      commit("setPortal", value)
      return dispatch("fetchPortalConfig")
    },
    async fetchPortalConfig({ commit, state, rootState }) {
      try {
        const config = await api.fetchConfig({
          portal: state.portal,
          locale: rootState.i18n.locale,
        })
        commit("updatePortalConfig", config)
        commit("setPortalConfigFetched", true)
      } catch (e) {
        logError(e.message)
      }
    },
  },
}
