// eslint-disable-next-line
let defaultManifest = {
  name: "Stellenticket Portale",
  short_name: "Stellenticket",
  theme_color: "#c50e1f",
  background_color: "#ffffff",
  display: "standalone",
  orientation: "portrait",
  Scope: "/",
  start_url: "./index.html",
  icons: [
    {
      src: "img/icons/android-icon-36x36.png",
      sizes: "36x36",
      type: "image/png",
      density: "0.75",
    },
    {
      src: "img/icons/android-icon-48x48.png",
      sizes: "48x48",
      type: "image/png",
      density: "1.0",
    },
    {
      src: "img/icons/android-icon-72x72.png",
      sizes: "72x72",
      type: "image/png",
      density: "1.5",
    },
    {
      src: "img/icons/android-icon-96x96.png",
      sizes: "96x96",
      type: "image/png",
      density: "2.0",
    },
    {
      src: "img/icons/android-icon-144x144.png",
      sizes: "144x144",
      type: "image/png",
      density: "3.0",
    },
    {
      src: "img/icons/android-icon-192x192.png",
      sizes: "192x192",
      type: "image/png",
      density: "4.0",
    },
    {
      src: "img/icons/ms-icon-144x144.png",
      sizes: "144x144",
      type: "image/png",
    },
    {
      src: "img/icons/apple-icon-152x152.png",
      sizes: "152x152",
      type: "image/png",
    },
  ],
  splash_pages: null,
}

// eslint-disable-next-line
export default newManifest => {
  // const stringManifest = JSON.stringify(
  //   Object.assign({}, defaultManifest, newManifest)
  // )
  // const blob = new Blob([stringManifest], { type: "application/json" })
  // const manifestURL = URL.createObjectURL(blob)
  // document.querySelector("#manifest").setAttribute("href", manifestURL)
}
