<template>
  <div>
    <span v-dompurify-html="summary" @click="open = !open" />
    <a
      v-if="!open && more"
      href="#"
      class="text-primary more"
      @click.prevent="open = !open"
    >
      <font-awesome-icon :icon="['far', 'chevron-double-right']" size="xs" />{{
        $t("pagination.more")
      }}
    </a>
    <template v-if="open && more">
      <span v-dompurify-html="more" class="more-text" />
      <a href="#" class="text-primary less" @click.prevent="open = !open">
        <font-awesome-icon :icon="['far', 'chevron-double-left']" size="xs" />{{
          $t("pagination.less")
        }}
      </a>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "TruncatedText",
  props: {
    text: {
      type: String,
      required: true,
    },
    separator: {
      type: String,
      required: false,
      default: ".",
    },
  },
  data() {
    return {
      open: false,
    }
  },
  computed: {
    ...mapState({
      config: (state) => state.portalConfig,
    }),
    summary() {
      return this.text.substring(0, this.breakpoint)
    },
    more() {
      let more = this.text.substring(this.breakpoint)
      if (more.length < 1) {
        return
      }
      return more
    },
    breakpoint() {
      let point = this.text.indexOf(this.separator) + this.separator.length
      if (point < 1 || point + 250 >= this.text.length) {
        return this.text.length
      }
      return point
    },
  },
}
</script>

<style lang="scss" scoped>
.less,
.more {
  padding-left: 0.5rem;
}

a {
  white-space: nowrap;
}
</style>
