import axios from "axios"
import appConfig from "@/config"

import Qs from "qs"

const instance = axios.create({
  baseURL: appConfig.api_endpoint,
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: "brackets" })
  },
})

// instance.interceptors.request.use(config => {
//   config.params.portal = store.getters.portal
//   config.params.locale = store.getters.storedLocale

//   return config
// })

export default instance
