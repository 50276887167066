export const routeToDesktopMapping = (router, store) => {
  if (!store) {
    throw "Route-to-Desktop mapping failed: Store not defined."
  }
  let path = store.state.portalConfig.desktopUrl
  switch (router?.currentRoute.name) {
    case "offers":
      path = `${path}/${store.state.i18n.locale}/offers/`
      break
    case "offer":
      path = `${path}/${store.state.i18n.locale}/offers/${router.currentRoute.params.id}`
      break
    case "privacy":
      path = `https://www.stellenticket.de/?custom_id=Datenschutz&lang=${store.state.i18n.locale}`
      break
    case "contact":
      path = `https://www.stellenticket.de/?custom_id=Kontakt&lang=${store.state.i18n.locale}`
      break
    default:
      path = `${path}/${store.state.i18n.locale}`
      break
  }
  return path
}
