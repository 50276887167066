<template>
  <div class="main-menu">
    <transition name="slide-in-out-right">
      <nav
        v-show="open"
        key="navigation"
        :aria-expanded="open"
        class="primary"
        role="navigation"
        aria-label="Main menu"
      >
        <div class="header">
          <div class="headline">
            <div class="logo"><img src="@/assets/logo_st_ticket_w.svg" /></div>
            <router-link :to="{ name: 'home' }" class="title">
              <span>STELLENTICKET</span><br />
              {{ title }}
            </router-link>
          </div>
          <a
            id="main-menu-close"
            href="#"
            role="button"
            class="menu-close"
            aria-controls="main-menu"
            aria-label="Close main menu"
            @click.prevent="open = false"
          >
            <span class="sr-only">Close main menu</span>
            <font-awesome-icon
              class="text-main menu-close-icon"
              icon="times"
              size="lg"
            />
          </a>
        </div>
        <div class="menus">
          <div class="links">
            <ul>
              <li>
                <router-link :to="{ name: 'home' }" class="content">
                  <font-awesome-icon icon="home" fixed-width size="lg" />
                  <span v-t="{ path: 'navigation.home' }"></span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'offers' }" class="content">
                  <font-awesome-icon icon="list" fixed-width size="lg" />
                  <span v-t="{ path: 'navigation.offers' }"></span>
                </router-link>
              </li>

              <li v-if="portalConfig.employers">
                <router-link :to="{ name: 'employers' }" class="content">
                  <font-awesome-icon icon="user-chart" fixed-width size="lg" />
                  <span v-t="{ path: 'navigation.employers' }"></span>
                </router-link>
              </li>

              <!--
              <li>
                <router-link :to="{ name: 'about' }" class="content">
                  <font-awesome-icon icon="info" fixed-width />
                  <span v-t="{ path: 'navigation.about' }"></span>
                </router-link>
              </li>
            -->

              <li>
                <router-link :to="{ name: 'contact' }" class="content">
                  <font-awesome-icon icon="at" fixed-width size="lg" />
                  <span v-t="{ path: 'navigation.contact' }"></span>
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'privacy' }" class="content">
                  <font-awesome-icon icon="user-lock" fixed-width size="lg" />
                  <span v-t="{ path: 'navigation.privacy' }"></span>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="footer">
            <ul>
              <li><language-selector class="content" /></li>
              <li v-if="showPortalSwitcher">
                <portal-switch class="content" />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </transition>
    <transition name="fade">
      <a
        v-show="open"
        key="backdrop"
        :aria-hidden="!open"
        class="backdrop"
        tabindex="-1"
        @click="open = false"
      />
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex"

import { mapComputed } from "@/utils/store_utils"

import LanguageSelector from "@/components/LanguageSelector.vue"

export default {
  name: "MainMenu",
  components: {
    LanguageSelector,
    "portal-switch": () => import("@/components/PortalSwitch.vue"),
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    open: mapComputed("mainMenu", "open"),
    showPortalSwitcher() {
      return process.env.NODE_ENV == "development"
    },
    ...mapState(["portalConfig"]),
  },
  watch: {
    $route() {
      this.open = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";
@import "@/scss/transitions.scss";
@import "@/scss/measures.scss";

.main-menu {
  background-color: var(--bgMain);

  nav {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    height: 100%;
    overflow-y: auto;
    background-color: var(--bgMain);

    ul {
      display: inline-block;
      width: 100%;
      padding: 0;
      margin: 1rem 0;
      list-style-type: none;

      li {
        margin: 0;

        a {
          color: var(--textMain);

          &:hover {
            background-color: $overlay-hover-item;
          }

          &.router-link-exact-active {
            background-color: $overlay-active-item;
          }
        }

        .content {
          display: block;
          width: 100%;
          height: 100%;
          padding: 0.8rem 3rem 0.8rem 1.2rem;
          margin-bottom: 0.5rem;
          overflow: hidden;
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;

          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .headline {
        display: flex;
        flex: 1 1 70%;
        align-items: center;
        justify-content: flex-start;
        padding: 1rem 1rem 1rem 1.3rem;
        text-align: left;

        .logo {
          flex: 0 0 1.3rem;
          margin-right: 0.5rem;
          text-align: center;

          img {
            display: block;
            width: 100%;
          }
        }

        .title {
          flex: 1 1 85%;
          overflow: hidden;
          color: var(--textMain);
          text-overflow: ellipsis;
          white-space: nowrap;

          span {
            font-weight: bold;
          }
        }
      }

      #main-menu-close {
        padding: $base-spacing-small 1rem;
      }

      .menu-close-icon {
        color: $gray-lighter;
      }
    }

    .menus {
      display: flex;
      flex: 1 1 $menu-height;
      flex-direction: column;
      justify-content: space-between;
      height: $menu-height;
      overflow-y: auto;
      border-top: 1px solid var(--textMain);
    }

    .footer {
      flex: 0 0 $header-height;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    display: block;
    width: 100%;
    height: 100%;
    cursor: default;
    content: "";
    background: rgba(0, 0, 0, 0.5);
  }

  /* Screen reader only */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
</style>
