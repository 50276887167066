import Vue from "vue"

import Router from "vue-router"
Vue.use(Router)

import Vuex from "vuex"
Vue.use(Vuex)

import VueI18n from "vue-i18n"
Vue.use(VueI18n)

import VueDomPurifyHTML from "vue-dompurify-html"
Vue.use(VueDomPurifyHTML)

import AsyncComputed from "vue-async-computed"
Vue.use(AsyncComputed)
