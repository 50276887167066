export default {
  pageTitle: "routeName",
  mounted() {
    this.$store.commit("setPageTitle", this.pageTitle)
  },
  computed: {
    pageTitle() {
      if (this.$options.pageTitle == "routeName" && this.$route?.name) {
        return this.$t(`navigation.${this.$route.name}`)
      } else if (this.$options.pageTitle) {
        return this.$options.pageTitle
      }
    },
  },
  watch: {
    pageTitle(val) {
      this.$store.commit("setPageTitle", val)
    },
  },
}
