import Router from "vue-router"

import Home from "@/views/Home.vue"
import store from "@/store"
import { defaultLocale } from "@/i18n"

const router = new Router({
  routes: [
    {
      path: "/:locale/offers",
      name: "offers",
      props: true,
      component: () =>
        import(/* webpackChunkName: "offers" */ "./views/Offers.vue"),
    },
    {
      path: "/:locale/offers/:id",
      name: "offer",
      props: true,
      component: () =>
        import(/* webpackChunkName: "offer" */ "./views/JobOffer.vue"),
    },
    {
      path: "/:locale/employers",
      name: "employers",
      props: true,
      component: () =>
        import(/* webpackChunkName: "offers" */ "./views/Employers.vue"),
    },
    {
      path: "/:locale/employers/:id",
      name: "employer",
      props: true,
      component: () =>
        import(/* webpackChunkName: "offer" */ "./views/Employer.vue"),
    },
    {
      path: "/:locale/contact",
      name: "contact",
      component: () =>
        import(/* webpackChunkName: "contact" */ "./views/Contact.vue"),
    },
    {
      path: "/:locale/privacy",
      name: "privacy",
      component: () =>
        import(/* webpackChunkName: "privacy" */ "./views/Privacy.vue"),
    },
    {
      path: "/:locale/",
      name: "home",
      component: Home,
    },
    {
      path: "*",
      redirect: { name: "home", params: { locale: defaultLocale } },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.name == "offers") {
      return { x: 0, y: savedPosition.y }
    }
    return { x: 0, y: 0 }
  },
})

// Change locale if it changes in params
router.beforeEach(async (to, from, next) => {
  if (to.params.locale != from?.params.locale) {
    await store.dispatch("changeLocale", to.params.locale)
  }
  next()
})

export default router
