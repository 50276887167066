import api from "@/api/global"
import logError from "@/utils/log_error"

export default {
  state: {
    fetched: false,
    enabledPortals: {},
    fieldofsGroups: {},
    jobclassificationGroups: {},
  },
  mutations: {
    setGlobalConfigFetched(state, value) {
      state.fetched = value
    },
    updateGlobalConfig(state, config) {
      state.enabledPortals = config.enabled_portals
      state.fieldofsGroups = config.fieldofs_groups
      state.jobclassificationGroups = config.jobclassification_groups
    },
  },
  actions: {
    async fetchGlobalConfig({ commit, dispatch }) {
      try {
        const config = await api.fetchConfig()
        commit("updateGlobalConfig", config)
        commit("setGlobalConfigFetched", true)

        if (process.env.NODE_ENV === "development") {
          await dispatch("changePortal", "Ernst-Abbe-Hochschule Jena")
        } else {
          const host = window.location.hostname
          if (config.enabled_portals[host]) {
            await dispatch("changePortal", config.enabled_portals[host])
          }
        }
      } catch (e) {
        logError(e.message)
      }
    },
  },
}
