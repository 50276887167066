<template>
  <transition name="fade">
    <div class="modal-wrapper" @click="closePopUp">
      <div class="modal-container">
        <div class="text-main modal">
          <p>{{ $t("hints.mobilePage") }}</p>
          <a :href="redirectRoute">
            <button class="default">
              {{ $t("hints.mobileYes") }}
            </button>
          </a>
          <a @click="closePopUp">
            <button class="default">
              {{ $t("hints.mobileNo") }}
            </button>
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { routeToDesktopMapping } from "@/utils/routing_utils"

export default {
  name: "MobileDialog",
  computed: {
    redirectRoute() {
      return routeToDesktopMapping(this.$router, this.$store)
    },
  },
  watch: {
    $route() {
      this.closePopUp()
    },
  },
  mounted() {
    document.body.addEventListener("keyup", this.closeOnEsc)
  },
  destroyed() {
    document.body.removeEventListener("keyup", this.closeOnEsc)
  },
  methods: {
    closePopUp() {
      this.$emit("close-mobile")
    },
    closeOnEsc(e) {
      if (e.keyCode === 27) {
        this.closePopUp()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/colors.scss";
@import "@/scss/buttons.scss";
@import "@/scss/measures.scss";

.modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 98;
  width: 100%;
  max-width: $max-app-width;
  height: auto;
  padding: 2rem $base-spacing;
  background-color: $overlay-color;

  .modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 30rem;
    height: 100%;
    margin: 0 auto;

    .modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;

      a {
        width: 100%;

        button {
          width: 100%;
          padding: $base-spacing-small;
          margin: $base-spacing-very-small;
        }
      }
    }
  }
}
</style>
