import { mapState, mapMutations } from "vuex"

export function mapComputed(namespace, name) {
  return {
    ...mapState(namespace, { get: name }),
    ...mapMutations(namespace, {
      set: `set${name[0].toUpperCase() + name.slice(1)}`,
    }),
  }
}
