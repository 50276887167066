import globalConfig from "@/store/global_config"
import i18n from "@/store/i18n"
import mainMenu from "@/store/main_menu"
import offerList from "@/store/offer_list"
import pageTitle from "@/store/page_title"
import portalConfig from "@/store/portal_config"

export default {
  modules: { offerList, mainMenu, portalConfig, globalConfig, pageTitle, i18n },
  state: {
    filterOpen: false,
  },
  mutations: {
    openFilter(state) {
      state.filterOpen = true
    },
    closeFilter(state) {
      state.filterOpen = false
    },
  },
}
