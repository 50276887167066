import Vue from "vue"

import "@/init"
import "@/font_awesome"
import "@/markdown"

import store from "@/store"
import i18n from "@/i18n"
import router from "@/router"
import App from "@/App.vue"

Vue.config.productionTip = false

const main = async () => {
  await store.dispatch("fetchGlobalConfig")

  new Vue({
    store,
    i18n,
    router,
    render: (h) => h(App),
  }).$mount("#app")
}

main()
