import VueI18n from "vue-i18n"

import api from "@/api/translations"

export const allowedLocales = ["de", "en"]
export const defaultLocale = "de"
export const loadedLocales = []

const dateFormats = {
  time: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  },
  date: {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  },
  full: {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  },
}

export const dateTimeFormats = {
  de: dateFormats,
  en: dateFormats,
}

function setI18nLocale(locale) {
  i18n.locale = locale
  document.querySelector("html").setAttribute("lang", locale)
}

export const i18n = new VueI18n({
  silentTranslationWarn: process.env.NODE_ENV === "production",
  locale: "xxx",
  fallbackLocale: defaultLocale,
  dateTimeFormats,
})

export function currentLocale() {
  return i18n.locale
}

export function isAllowedLocale(locale) {
  return allowedLocales.includes(locale)
}

export async function loadLocale(locale) {
  if (!loadedLocales.includes(locale)) {
    const translations = api.fetchTranslations(locale)
    const msgs = import(
      /* webpackChunkName: "langs-[request]" */ `@/langs/${locale}`
    )

    i18n.setLocaleMessage(locale, (await msgs).default)
    i18n.mergeLocaleMessage(locale, await translations)

    loadedLocales.push(locale)
  }
}

export async function setLocale(locale) {
  if (!allowedLocales.includes(locale)) {
    throw new Error(`Locale ${locale} invalid or not allowed`)
  }

  await loadLocale(locale)
  setI18nLocale(locale)
}

export default i18n
