<template>
  <div class="wrapper">
    <label
      for="language-selection"
      class="label"
      aria-label="language-selection"
      hidden
    >
      <span class="selection-choice">Please choose a language</span>
    </label>
    <font-awesome-icon icon="comments" fixed-width size="lg" />
    <span
      >{{ $t("locales.language") }}: {{ languages[currentLocale].name }}</span
    >
    <font-awesome-icon :icon="['far', 'angle-down']" class="angle" size="lg" />
    <select
      id="language-selection"
      ref="language-selection"
      :value="currentLocale"
      class="selector"
      @input="toggleLanguage"
    >
      <option
        v-for="language in languages"
        :key="language.locale"
        :value="language.locale"
      >
        {{ language.icon }} {{ language.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "LanguageSelector",
  data() {
    return {
      languages: {
        de: {
          icon: "🇩🇪",
          name: "Deutsch",
          locale: "de",
        },
        en: {
          icon: "🇬🇧",
          name: "English",
          locale: "en",
        },
        // ,
        // fr: {
        //   icon: "🇫🇷",
        //   name: "Français",
        //   locale: "fr"
        // }
      },
    }
  },
  computed: {
    ...mapState({
      currentLocale(state) {
        return state.i18n.locale
      },
    }),
  },
  methods: {
    toggleLanguage(e) {
      this.$router.push({ params: { locale: e.target.value } })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/measures.scss";

.wrapper {
  position: relative;

  > .angle {
    position: absolute;
    right: $base-spacing;
  }
}

.selector {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0.01;
}

svg ~ span {
  margin-left: 0.5rem;
}
</style>
