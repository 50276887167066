import Vue from "vue"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
Vue.component("FontAwesomeIcon", FontAwesomeIcon)

import { library } from "@fortawesome/fontawesome-svg-core"

// Import icons here and add them to library below.
// Please sort all imports and adds alphabetically.
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

import {
  faAngleLeft,
  faAngleRight,
  faAt,
  faBars,
  faBuilding,
  faChartBar,
  faCheck,
  faComments,
  faGraduationCap,
  faHome,
  faInfo,
  faLeaf,
  faList,
  faNotesMedical,
  faPaintBrush,
  faPlus,
  faSitemap,
  faTimes,
  faUndo,
  faUserChart,
  faUserLock,
  faUsers,
  faWrench,
} from "@fortawesome/pro-solid-svg-icons"

import {
  faAngleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faDesktop,
  faEnvelope,
  faGlobe,
  faMobileAndroid,
  faShareAlt,
  faPrint,
} from "@fortawesome/pro-regular-svg-icons"

library.add(
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAt,
  faBars,
  faBuilding,
  faChartBar,
  faCheck,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faComments,
  faDesktop,
  faEnvelope,
  faGlobe,
  faGraduationCap,
  faHome,
  faInfo,
  faInfoCircle,
  faLeaf,
  faList,
  faMobileAndroid,
  faNotesMedical,
  faPaintBrush,
  faPlus,
  faPrint,
  faShareAlt,
  faSitemap,
  faTimes,
  faUndo,
  faUserChart,
  faUserLock,
  faUsers,
  faWrench
)
