const logError = (msg) => {
  const log = document.getElementById("errorLog")
  const node = document.createElement("p")
  node.innerHTML = `Error: "${msg}"`

  log.appendChild(node)

  if (log.childElementCount == 1) {
    prependContact(log)
  }
}

const prependContact = (log) => {
  const node = document.createElement("p")
  node.innerHTML =
    "Please try to reload the page or contact info@stellenticket.de."
  log.prepend(node)
}

export default logError
