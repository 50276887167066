<template>
  <div class="home">
    <div
      :style="{
        backgroundColor: portalConfig.header.tileColor,
        backgroundImage: portalConfig.header.tileUrl
          ? `url('${portalConfig.header.tileUrl}')`
          : 'none',
      }"
      class="header"
    >
      <div class="header-row">
        <h1 class="header-content">{{ portalConfig.header.text }}</h1>
      </div>
      <div v-if="portalConfig.logos.length > 0" class="header-row">
        <div class="header-content logos">
          <a
            v-for="image in portalConfig.logos"
            :key="image.src"
            :href="image.href"
            :style="{ flexBasis: image.width || '100%' }"
            class="header-image-container"
            ><img :src="image.src" alt="logo of institution" />
          </a>
        </div>
      </div>
    </div>
    <section class="portal-info">
      <h3 v-text="portalConfig.welcomeTitle" />
      <truncated-text :text="portalConfig.welcomeText" class="welcome-text" />
      <div class="link-wrapper">
        <router-link :to="{ name: 'offers' }" class="link">
          <font-awesome-icon class="primary" icon="list" />
          <div class="sublink">
            <span v-t="{ path: 'navigation.offers' }"></span>
            <span v-t="{ path: 'navigation.offers_sub' }"></span>
          </div>
        </router-link>
      </div>
      <div v-if="portalConfig.employers" class="link-wrapper">
        <router-link :to="{ name: 'employers' }" class="link">
          <font-awesome-icon class="primary" icon="user-chart" />
          <div class="sublink">
            <span v-t="{ path: 'navigation.employers' }"></span>
            <span v-t="{ path: 'navigation.employers_sub' }"></span>
          </div>
        </router-link>
      </div>
      <!--
        <div class="link-wrapper">
          <router-link :to="{ name: 'about' }" class="link">
            <font-awesome-icon class="primary" icon="info" />
            <div class="sublink">
              <span v-t="{ path: 'navigation.about' }"></span>
              <span v-t="{ path: 'navigation.about_sub' }"></span>
            </div>
          </router-link>
        </div>
      -->
      <div class="link-wrapper">
        <router-link :to="{ name: 'contact' }" class="link">
          <font-awesome-icon class="primary" icon="at" />
          <div class="sublink">
            <span v-t="{ path: 'navigation.contact' }"></span>
            <span v-t="{ path: 'navigation.contact_sub' }"></span>
          </div>
        </router-link>
      </div>
      <div class="link-wrapper">
        <router-link :to="{ name: 'privacy' }" class="link">
          <font-awesome-icon class="primary" icon="user-lock" />
          <div class="sublink">
            <span v-t="{ path: 'navigation.privacy' }"></span>
            <span v-t="{ path: 'navigation.privacy_sub' }"></span>
          </div>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex"

import pageTitle from "@/mixins/page_title"
import TruncatedText from "@/components/TruncatedText.vue"

export default {
  name: "Home",
  pageTitle: "",
  components: { TruncatedText },
  mixins: [pageTitle],
  computed: {
    ...mapState(["portalConfig"]),
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/measures.scss";

.portal-info {
  margin: $base-spacing 0;
}

.welcome-text {
  margin-bottom: $base-spacing-big;
  hyphens: auto;
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

  svg {
    flex: 0 0 15vw;
    max-width: $header-height;
    height: 15vw;
    max-height: $header-height;
    padding: $icon-padding;
    color: var(--textMain);
    background-color: var(--bgMain);
    border-radius: 5px;
  }

  .sublink {
    display: flex;
    flex: 1 1 70vw;
    flex-direction: column;
    align-content: flex-end;
    margin-left: 1rem;
    color: black;

    span:first-of-type {
      font-weight: bold;
    }
  }
}

.header {
  padding-top: 0.5rem;
  padding-bottom: $base-spacing;
  background-position: center;
  background-size: cover;

  .header-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: $border-radius-strong;
  }

  .header-content {
    width: 95%;
    max-width: 30rem;
    padding: $base-spacing-small;
    background-color: $white;
    border-radius: 5px;
  }

  .header-image-container {
    display: flex;
    justify-content: center;
    max-height: $title-picture;
    margin-right: $base-spacing-small;

    + .header-image-container {
      margin-left: $base-spacing-small;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  h1 {
    text-align: center;
  }

  .logos {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    white-space: nowrap;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: inherit;
  }
}
</style>
