export default {
  state: {
    title: "routeName",
  },
  mutations: {
    setPageTitle(state, value) {
      state.title = value
    },
  },
}
