import axios from "@/axios"

export default {
  async fetchConfig({ portal, locale }) {
    const params = {
      portal,
      locale,
    }

    const res = await axios.get("portal_config", { params })

    if (!res.data || !Object.keys(res.data).length) {
      throw Error("Error fetching portal config")
    }

    return res.data
  },
}
