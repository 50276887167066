export default {
  namespaced: true,
  state: {
    offers: [],
    meta: {},
    lastUpdated: null,
    page: {
      size: 10,
      number: 0,
    },
    filter: {
      fulltextsearch: "",
      worktype: [],
      jobclassification: [],
    },
  },
  mutations: {
    setOffers(state, value) {
      state.offers = value
    },
    setMeta(state, value) {
      state.meta = value
    },
    setLastUpdated(state, value) {
      state.lastUpdated = value
    },
    setPage(state, value) {
      state.page = value
    },
    setFilter(state, value) {
      state.filter = value
    },
    updateFilter(state, value) {
      Object.assign(state.filter, value)
    },
  },
}
