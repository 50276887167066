<template>
  <div v-if="ready" id="app">
    <header v-if="pageTitle || $route.name == 'home'" class="primary">
      <a
        v-if="!hideMainSite"
        id="backwards-button"
        href="#"
        class="backwards-button"
        :class="{ hidden: $route.name == 'home' }"
        @click.prevent="navigate"
      >
        <font-awesome-icon
          class="primary back-icon"
          icon="angle-left"
          size="lg"
          fixed-width
        />
      </a>
      <span class="route-title">{{ pageTitle }}</span>
      <a
        v-if="!hideMainSite"
        href="#"
        role="button"
        class="menu-toggle"
        aria-controls="main-menu"
        aria-label="Open main menu"
        @click.prevent="toggleMenu"
      >
        <span class="sr-only">Open main menu</span>
        <font-awesome-icon
          class="primary menu-toggle-icon"
          icon="bars"
          fixed-width
        />
      </a>
    </header>
    <main>
      <main-menu :title="menuTitle" />
      <router-view id="router-view" />
    </main>
    <mobile-dialog
      v-if="!isMobile && !hideMobilePopUp"
      @close-mobile="hidePopUp"
    />
    <footer class="primary">
      <div v-if="!isMobile" class="desktop-link">
        <a class="text-main" @click="openMobileDialog">
          <font-awesome-icon
            :icon="['far', 'mobile-android']"
            class="primary menu-toggle-icon"
            fixed-width
          />
          <span>▶︎</span>
          <font-awesome-icon
            :icon="['far', 'desktop']"
            class="primary menu-toggle-icon"
            fixed-width
          />
        </a>
      </div>
      <div class="poweredBy">
        <a href="https://www.stellenticket.de" target="_blank">
          <span>powered by</span>
          <img id="powered-by-logo" src="@/assets/logo_st_ticket_w.svg" />
        </a>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex"
import MD from "mobile-detect"

import MainMenu from "@/components/MainMenu.vue"
import MobileDialog from "@/components/MobileDialog.vue"
import setManifest from "@/manifest"
import analytics from "@/analytics"

export default {
  components: { MainMenu, MobileDialog },
  data() {
    return {
      hideMobilePopUp: false,
      isMobile: !!new MD(window.navigator.userAgent).mobile(),
    }
  },
  computed: {
    ...mapState({
      configsFetched(state) {
        return state.portalConfig.fetched && state.globalConfig.fetched
      },
      pageTitle(state) {
        return state.pageTitle.title
      },
      menuTitle(state) {
        return state.portalConfig.partner.nameShort
      },
    }),
    hideMainSite() {
      return !!this.$route.query.jobwall
    },
    ready() {
      // Make sure that all configs are present *and* we have a locale in
      // our route, because it is expected to be present for all views.
      return this.configsFetched && this.$route.params.locale
    },
  },
  watch: {
    // order is important for following listeners (will set matomo page-title)
    "$store.state.portalConfig.header": {
      handler(val) {
        this.setTitle(val)
      },
      deep: true,
    },
    "$store.state.portalConfig.colors": {
      handler() {
        this.initColors()
      },
      deep: true,
    },
  },
  mounted() {
    this.setTitle(this.$store.state.portalConfig.header)
    this.initMatomo()
    this.initColors()
  },
  methods: {
    ...mapMutations({
      toggleMenu: "mainMenu/toggle",
    }),
    navigate() {
      // find path to last path segment (from `/a/b/c` strip of `/c`)
      const match = this.$route.path.match(/(.*)\/[^/]*\/?$/)

      this.$router.push({ path: (match && match[1]) || "/" })
    },
    openMobileDialog() {
      this.hideMobilePopUp = false
    },
    hidePopUp() {
      this.hideMobilePopUp = true
    },
    setTitle(val) {
      document.title = val.textShort || "Stellenticket Portal"
      setManifest({
        name: val.text,
        short_name: val.textShort,
      })
      localStorage.clear()
    },
    initMatomo() {
      analytics.init({
        url: this.$store.state.portalConfig.matomo.url,
        id: this.$store.state.portalConfig.matomo.id,
        router: this.$router,
      })
    },
    initColors() {
      const attributes = [
        "--fontColor",
        "--linkColor",
        "--bgMain",
        "--bgAlt",
        "--bgHigh",
        "--textMain",
        "--textAlt",
        "--textHigh",
      ]

      for (let attr of attributes) {
        const cssAttr = attr.substring(2)
        document.documentElement.style.setProperty(
          attr,
          this.$store.state.portalConfig.colors[cssAttr]
        )
      }
    },
  },
}
</script>

<style lang="scss">
@use "sass:math";

@import "~normalize.css";
@import "@/scss/typography.scss";
@import "@/scss/colors.scss";
@import "@/scss/transitions.scss";
@import "@/scss/buttons.scss";
@import "@/scss/measures.scss";

:root {
  --fontColor: #444;
  --linkColor: #47c;
  --bgMain: #47c;
  --bgAlt: #eaf0f9;
  --bgHigh: #ccd9e6;
  --textMain: #fff;
  --textAlt: #444;
  --textHigh: #444;
}

html {
  width: 100%;
  height: 100%;
  color: var(--fontColor, inherit);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

a {
  color: var(--linkColor);
}

ul {
  padding: 0.1rem 0.5rem;
  margin: 0.1rem 0.5rem;
  list-style-type: disc;

  li {
    margin: 0.5rem;
  }
}

#app {
  margin: 0 auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.back-icon {
  transition: opacity 0.5s;
}

::placeholder {
  opacity: 0.9;
}

.primary {
  color: var(--textMain);
  background-color: var(--bgMain);
}

.alternative {
  color: var(--textAlt);
  background-color: var(--bgAlt);
}

.highlight {
  color: var(--textHigh);
  background-color: var(--bgHigh);
}

.text-main {
  color: var(--textMain);
}

.text-alt {
  color: var(--textAlt);
}

.text-high {
  color: var(--textHigh);
}

.text-primary {
  color: var(--bgMain);
}

header {
  position: fixed;
  top: 0;
  z-index: 15;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $header-height;
  color: white;
  background-color: var(--bgMain);

  .menu-toggle {
    display: inline-block;
    padding: 0 $base-spacing;
  }

  .menu-toggle-icon {
    color: $white;
  }

  .backwards-button {
    padding: 0 $base-spacing;

    &.hidden {
      visibility: hidden;
    }
  }

  .route-title {
    flex-grow: 1;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--textMain);
    text-align: center;
  }
}

main {
  min-height: $min-main-height;
  padding-top: $header-height;
  padding-bottom: math.div($footer-height, 2);
  background-color: $white;

  section {
    padding: 0 $base-spacing;
  }
}

footer {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  height: $footer-height;
  padding: 0.2rem 0;
  color: $white;
  background-color: var(--bgMain);

  > div {
    display: flex;
    align-self: center;
    width: 50%;
    max-width: 5rem;
    margin: 0 0.3rem;
    font-size: 0.7rem;

    a {
      display: flex;
      color: inherit;
      cursor: pointer;

      span {
        margin-right: 0.1rem;
        white-space: nowrap;
      }

      svg,
      img {
        align-self: center;
        width: auto;
        height: 1.5rem;
      }
    }
  }

  .desktop-link {
    justify-content: flex-start;
    order: 2;
  }

  .poweredBy {
    justify-content: flex-end;
    order: 1;

    span {
      align-self: flex-end;
    }

    img#powered-by-logo {
      padding: 0.1rem;
    }
  }
}

#app,
header,
footer {
  max-width: $max-app-width;
  overflow: hidden;
}
</style>
