import { setLocale } from "@/i18n"

export default {
  state: {
    locale: "",
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale
    },
  },
  actions: {
    changeLocale({ commit, dispatch }, locale) {
      commit("setLocale", locale)
      return Promise.all([setLocale(locale), dispatch("fetchPortalConfig")])
    },
  },
}
