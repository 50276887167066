import axios from "@/axios"

export default {
  async fetchTranslations(locale) {
    const params = { locale }

    const res = await axios.get("lang", { params })

    if (!res.data || !Object.keys(res.data).length) {
      throw Error(`Missing translations for '${JSON.stringify(params)}'`)
    }

    return res.data
  },
}
