export default {
  namespaced: true,
  state: {
    open: false,
  },
  mutations: {
    setOpen(state, value) {
      state.open = value
    },
    toggle(state) {
      state.open = !state.open
    },
    close(state) {
      state.open = false
    },
    open(state) {
      state.open = true
    },
  },
}
