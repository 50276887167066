import Vue from "vue"
import matomo from "vue-matomo"

let inited = false
let init

if (process.env.NODE_ENV != "production") {
  init = async () => {
    // noop
  }
} else {
  init = async ({ url, id, router }) => {
    if (inited) {
      throw "Matomo already inited."
    }

    if (url && id) {
      inited = true

      matomo(Vue, {
        host: url,
        siteId: id,
        router,
        disableCookies: true,
      })
    }
  }
}

export default { init }
