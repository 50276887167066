import axios from "@/axios"

export default {
  async fetchConfig() {
    const res = await axios.get("global_config")

    if (!res.data || !Object.keys(res.data).length) {
      throw Error("Error fetching global config")
    }

    return res.data
  },
}
